export class Constants {
	static maxSearchExportIds: number = 1024;
}

export interface ILookupVM {
	value: string;
	label: string;
	name: string;
	order: number;
}
export interface IHostLookupVM extends ILookupVM {
	organisation: string;
	organisationId: string;
	emailAddress: string;
	phoneNumber: string;
	mobilePhoneNumber: string;
	visitorInformation: string;
}
export interface ISiteDetailVM {
	siteId: number;
	siteName: string;
	addressVM: IAddressVM;
}
export interface IPersonNameVM {
	title: string;
	firstName: string;
	lastName: string;
}
export interface IAddressVM {
	addressLine1: string;
	addressLine2: string;
	addressLine3: string;
	city: string;
	postcode: string;
}
export class ClientDetailVM {
	constructor(id: number, name: string, addressVM: IAddressVM, contactPersonNameVM: IPersonNameVM, contactPersonPosition: string, contactPersonEmail: string,
		contactPersonMobile: string, serviceProviderTypeId: string, dataProtectionPersonNameVM: IPersonNameVM, dataProtectionPersonEmail: string,
		dataProtectionPersonMobile: string, icoRegistrationWebLink: string, icoRegistrationNumber: string, icoExpiryDate: string) {
		this.id = id;
		this.name = name;
		this.addressVM = addressVM;
		this.contactPersonNameVM = contactPersonNameVM;
		this.contactPersonPosition = contactPersonPosition;
		this.contactPersonEmail = contactPersonEmail;
		this.contactPersonMobile = contactPersonMobile;
		this.serviceProviderTypeId = serviceProviderTypeId;
		this.dataProtectionPersonNameVM = dataProtectionPersonNameVM;
		this.dataProtectionPersonEmail = dataProtectionPersonEmail;
		this.dataProtectionPersonMobile = dataProtectionPersonMobile;
		this.icoRegistrationWebLink = icoRegistrationWebLink;
		this.icoRegistrationNumber = icoRegistrationNumber;
		this.icoExpiryDate = icoExpiryDate;
	}

	id: number;
	name: string;
	addressVM: IAddressVM;
	contactPersonNameVM: IPersonNameVM;
	contactPersonPosition: string;
	contactPersonEmail: string;
	contactPersonMobile: string;
	serviceProviderTypeId: string;
	dataProtectionPersonNameVM: IPersonNameVM;
	dataProtectionPersonEmail: string;
	dataProtectionPersonMobile: string;
	icoRegistrationWebLink: string;
	icoRegistrationNumber: string;
	icoExpiryDate: string;
}


export class ClientSiteDetailVM {
	constructor(id: number, siteId: number, siteName: string, siteAddressVM: IAddressVM, clientId: number, clientName: string,
		contactPersonNameVM: IPersonNameVM, contactPersonPosition: string, contactPersonEmail: string, contactPersonMobile: string,
		billingDetailsVM: IBillingDetailVM, systemModules: SystemModule, landingPage: SystemModule) {
		this.id = id;
		this.siteId = siteId;
		this.siteName = siteName;
		this.siteAddressVM = siteAddressVM;
		this.clientId = clientId;
		this.clientName = clientName;
		this.contactPersonNameVM = contactPersonNameVM;
		this.contactPersonPosition = contactPersonPosition;
		this.contactPersonEmail = contactPersonEmail;
		this.contactPersonMobile = contactPersonMobile;
		this.billingDetailVM = billingDetailsVM;
		this.systemModules = systemModules;
		this.landingPage = landingPage;
	}

	id: number;
	siteId: number;
	siteName: string;
	siteAddressVM: IAddressVM;
	clientId: number;
	clientName: string;
	contactPersonNameVM: IPersonNameVM;
	contactPersonPosition: string;
	contactPersonEmail: string;
	contactPersonMobile: string;
	billingDetailVM: IBillingDetailVM;
	systemModules: SystemModule;
	landingPage: SystemModule;
}


export interface IBillingDetailVM {
	name: string;
	addressVM: IAddressVM;
	contactName: IPersonNameVM;
	contactPersonEmail: string;
	contactPersonMobile: string;
	auditValue: number;
	softwareValue: number;
	billingDate: string;
	frequency: BillingFrequencyType;
}

export class UserDetailVM {
	constructor(id: number, personNameVM: IPersonNameVM, username: string, position: string, emailAddress: string, mobilePhone: string,
		addressVM: IAddressVM, clientId: number, useClientAddress: boolean, userAdminLevel: AdministratorLevel) {
		this.id = id;
		this.personNameVM = personNameVM;
		this.username = username;
		this.position = position;
		this.emailAddress = emailAddress;
		this.mobilePhone = mobilePhone;
		this.addressVM = addressVM;
		this.clientId = clientId;
		this.useClientAddress = useClientAddress;
		this.userAdminLevel = userAdminLevel;
	}

	id: number;
	personNameVM: IPersonNameVM;
	username: string;
	position: string;
	emailAddress: string;
	mobilePhone: string;
	addressVM: IAddressVM;
	clientId: number;
	useClientAddress: boolean;
	userAdminLevel: AdministratorLevel;
}

export interface IActivityLogTemplateDetailVM {
	id: number;
	subject: string;
	subHeadings: ISelectOption[];
	userDefinedHeading: string;
	defaultDescription: string;
	assignmentInstructions: string;
	selectedDefaultPriorityLevel: string;
	priorityLevels: ISelectOption[];
	selectedDefaultStatus: string;
	statuses: ISelectOption[];
	notificationEmailAddresses: string;
	smsNotificationNumbers: string;
	notificationMessage: string;
	systemDefined: boolean;
	activityLogType: ActivityLogType;
}


export interface ISelectOption {
	value: string;
	text: string;
}

export class UserGroupDetailVM {
	constructor(id: number, groupName: string, clientId: number, permissionsVM: PermissionVM[]) {
		this.id = id;
		this.groupName = groupName;
		this.clientId = clientId;
		this.permissionsVM = permissionsVM;
	}
	id: number;
	groupName: string;
	clientId: number;
	permissionsVM: PermissionVM[];
}
export class PermissionVM {
	constructor(permissionLevel: PermissionLevelVM, systemFunction: SystemFunction) {
		this.permissionLevel = permissionLevel;
		this.systemFunction = systemFunction;
	}
	permissionLevel: PermissionLevelVM;
	systemFunction: SystemFunction;
}


// ENUMS

export enum BillingFrequencyType {
	None = 0,
	//Weekly = 1,
	Monthly = 2,
	Quarterly = 3,
	Annually = 4
}
export enum SystemModule {
	SystemDefault = 0,
	Dashboard = 1 << 0,
	ActivityLog = 1 << 1,
	Visitors = 1 << 2,
	Patrols = 1 << 3,
	Assets = 1 << 4,
	Documents = 1 << 5,
	Mobile = 1 << 6,
	CctvChecker = 1 << 7,
	PassHolders = 1 << 8,
	DataReleaseViewing = 1 << 9,
	Audit = 1 << 10,
	Locations = 1 << 11,
	//Messaging = 1 << 7,
	//Reports = 1 << 8,
	//CctvChecker = 1 << 9
}
export enum SystemFunction {
	Dashboard = 0,
	ActivityLog = 1,
	MyAccount = 2,
	//ChangePassword = 3,
	Visitors = 4,
	Patrols = 5,
	Assets = 6,
	Documents = 7,
	RestrictActivityLog = 8,
	Tasks = 9,
	Delete = 10,
	MobilePatrols = 11,
	ActivityLogReport = 12,
	AssetHandling = 13,
	MobileVisitors = 14,
	CctvChecker = 15,
	MobileAssets = 16,
	ReleaseAuthorisation = 17,
	PassHolders = 18,
	Audit = 19,
	Locations = 20,
	//Messaging,
	//Reports
}
export enum PermissionLevelVM {
	None = 1 << 0,
	Read = 1 << 1,
	Write = 1 << 2,
	Password = 1 << 3
}
export enum AdministratorLevel {
	None = 0,
	ClientAdministrator = 10,
	SystemAdministrator = 20,
	SuperAdministrator = 99
}
export enum PatrolTaskResponseType {
	Unknown,
	Checkbox,
	MultipleChoice,
	Comment
}

export enum DirectoryDataType {
	Host = "Host",
	Organisation = "Organisation"
}

export class SystemUrls {
	public static readonly login: string = "/Authentication/Login";
	public static readonly reauth: string = "/authentication/requesttokenasync";
	public static readonly visitorsCancelBookOut: string = "/visitors/CancelBookOutAsync";
	public static readonly visitorsCleansePersonalData: string = "/visitors/CleansePersonalDataAsync";
	public static readonly scanAsset: string = "/Assets/ScanAsset";
}

export interface ITableAction {
	actionType: string;
	id: string;
	text: string;
	href: string;
	ajaxMethod: string;
	ajaxSuccessMessage: string;
	target: string;
	cssClass: string;
}

export interface ITableList {
	displayItems: string[];
	items: string[];
	displayLength: number;
}

export interface ITableTextIcon {
	displayText: string;
	iconClass: string;
	iconTitle: string | null;
}

export interface ITableImageButton {
	displayText: string;
	iconClass: string;
	imageUrl: string;
}

export interface ITableMoreText {
	displayText: string | null;
	moreText: string | null;
}
export interface ITableTextInput {
	displayText: string | null;
	id: string | null;
	name: string | null;
	placeholder: string | null;
	isReadonly: boolean;
}
export interface ITableDropdown {
	selectedValue: string | null;
	options: ILookupVM[];
	id: string | null;
	name: string | null;
	includeChoose: boolean;
	isReadonly: boolean;
}
export interface ITableRadioInput {
	selectedValue: string | null;
	options: ILookupVM[];
	id: string | null;
	name: string | null;
	isReadonly: boolean;
}

export interface IViewTableAction extends ITableAction {
}

export interface IEditTableAction extends ITableAction {
	data: any;
}

export interface IDeleteTableAction extends ITableAction {
	isUndelete: boolean;
	entityName: string;
	confirmTitle: string;
	confirmMessage: string;
	data: any;
}

export interface ICustomTableAction extends ITableAction {
	iconClass: string;
	data: any;
}

export interface IDataTableColumnVM {
	type: DataTableColumnType;
	display: any;
	filter: any;
	sort: any;
}

export enum DataTableColumnType {
	Default = 0,
	Actions = 1,
	List = 2,
	MoreText = 3,
	TextIcon = 4,
	TextInput = 5,
	Dropdown = 6,
	RadioInput = 7,
	ImageButton = 8,
	ImageButtons = 9
}

export interface IActivityLogActivityTableDataVM {
	isRestrictedForCurrentUser: boolean;
}

export enum TaskType {
	Unknown = 0,
	ScheduledTask = 1,
	CctvCheck = 2
}
//export class TaskTypeUtilities {
//	public static GetDisplayText(type: TaskType): string {
//		switch (type) {
//			case TaskType.ScheduledTask:
//				return 'Calendar';
//			case TaskType.CctvCheck:
//				return 'CCTV Check';
//			default:
//				return 'Unknown';
//		}
//	}
//}

export enum TaskStatusType {
	Pending = 0,
	Completed = 1,
	Snoozed = 2,
	Overdue = 3,
	Missed = 4,
	NotCompleted = 5
}
export enum ActivityLogStatus {
	Open = 0,
	Closed = 1,
}

export enum ActivityLogType {
	ActivityLog = 0,
	CctvCheck = 1,
	DataReleaseFile = 2,
	DataReleaseUrl = 3,
	DataReleasePhysical = 4,
	DataViewing = 5,
	DataReleaseThirdParty = 6
}

export interface IClientEvent {
	type: any;
}

export class ScheduledTaskStatusChangedEventVM implements IClientEvent {
	public type: any;
	public subject: string = "";
	public description: string = "";

	public pendingChange: number = 0;
	public completedChange: number = 0;
	public oldStatus?: TaskStatusType;
	public newStatus?: TaskStatusType;
}
export class ActivityLogStatusChangedEventVM implements IClientEvent {
	type: any;
	subject: string = "";
	urn: number = 0;

	pendingChange: number = 0;
	completedChange: number = 0;
	oldStatus?: ActivityLogStatus;
	newStatus?: ActivityLogStatus;
}
export class VisitorStatusChangedEventVM implements IClientEvent {
	type: any;
	preBookedChange: number = 0;
	onSiteChange: number = 0;
	offSiteChange: number = 0;
}
export class AssetStatusChangedEventVM implements IClientEvent {
	type: any;
	issuedChange: number = 0;
	overdueChange: number = 0;
}


// Patrols ----------
export interface IViewPatrolTaskVM {
	Id: number;
	TaskName: string;
	CompletedTime: Date;
	Status: string; //PatrolStatusEnum
	StatusDescription: string;
	Reason: string;
	TaskIndex: number;
	Question: string;
	Answer: string;
	UploadId: number;
}
export interface IViewPatrolPointVM {
	Id: number;
	PointName: string;
	LocationDescription: string;
	Coordinates: string;

	Status: string; //PatrolStatusEnum
	StatusDescription: string;
	Reason: string;
	CompletedTime: Date;

	CompletedTasks: number;
	TotalTasks: number;
	TaskCompletedText: string;

	PointIndex: number;
	Tasks: IViewPatrolTaskVM[];
}

export enum AssetStatus {
	NotIssued = 0,
	Issued = 1,
	Overdue = 2,
	Missing = 3,
}

export interface IScanAssetResultVM {
	assetId: number;
	rfid: string;
	issueInstructions: string;
	status: AssetStatus;
	statusText: string;
	description: string;
	canBookout: boolean;
}

export interface IVisitorDetailAutocompleteVM {
	optionName: string;
	id: number;
	name: string;
	hostName: string;
	organisation: string;
	mobileNumber: string;
	emailAddress: string;
	vehicleRegistration: string;
	type: number | null;
}

export interface IUpdateAssetStatus {
	id: any;
	status: any;
}

export interface IGoogleMapSettings {
	apiKey: string;
	defaultPostcode: string;
	defaultLat?: number;
	defaultLon?: number;
	defaultZoom?: number;
}

export interface ILocationVM {
	id: number;
	name: string;
	description: string;
	latitude?: number;
	longitude?: number;
	floor: string;
	reference: string;
	zone: string;
}

export interface IDeleteLocationsInformationVM {
	noOfLocationsToDelete: number;
	activityLogCount: number;
	otherLocations: ILocationVM[];
}