//import pdfMake from "pdfmake/build/pdfmake";
//import pdfFonts from "pdfmake/build/vfs_fonts";
//pdfMake.vfs = pdfFonts.pdfMake.vfs;
import JsZip from "jszip";

//Need to add JSZip to the window to allow excel export
declare global {
	// ReSharper disable once InconsistentNaming
	interface Window { JSZip: any; }
}
window.JSZip = JsZip;

import $, { data } from 'jquery';
import "../../node_modules/bootstrap/js/dist/tooltip.js";
import "datatables.net";
import "datatables.net-bs4";
import "datatables.net-scroller";
import "datatables.net-select-bs4";
import "datatables.net-buttons";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";

import { IAjaxResponse, IAjaxError, IAjaxReponseData } from "../dogfish.server.interfaces";
import { EventHandler, IMultiEventHandler, MultiEventHandler } from "./EventUtilities";
import { Dialog } from "./DialogUtilities";
import { AjaxRequest, AjaxUrl, AjaxError } from "./AjaxUtilities";
import { DogfishUtilities } from "./DogfishUtilities";
import { Logger } from "./Logger";
import { defaultColumnDefRender, getActionsHtml, } from "./DataTableHelper.ColumnRenders";
import { Constants, ITableAction } from "../ViewModels";
import { FormUtilities } from "./FormUtilities";
import { Datepicker } from "./DatepickerUtilities";
import { IKeyedCollection, KeyedCollection } from "./Utilities.js";

export class DataTableHelper {
	private static isGlobalInitialized: boolean = false;

	private drilldownToggledEventHandler = new EventHandler<IDrilldownToggledEvent, void>();
	private preSelectEventHandler = new EventHandler<IPreSelectEvent, boolean>();
	private initCompleteEventHandler = new EventHandler();
	private refreshedEventHandler = new EventHandler();
	private searchEventHandler = new EventHandler();

	protected element: HTMLElement;
	private dt: DataTables.Api;
	private $wrapper: JQuery<HTMLElement>;
	isInitialised: boolean = false;

	private actionEventHandler: IMultiEventHandler<any, boolean> = new MultiEventHandler<any, boolean>();

	static noExportClass: string = '.no-export';

	public constructor(element: HTMLElement, settings?: DataTables.Settings, isAjax: boolean = true, triggerLoading: boolean = false) {
		DataTableHelper.initializeGlobalSettings();

		var isDataTable = $.fn.dataTable.isDataTable(element);
		if (settings == undefined && !isDataTable)
			throw new Error('DataTable is not initialised and no settings were passed through');

		this.element = element;

		if (!(<any>$.fn.DataTable).isDataTable(element)) {
			this.setAdvancedSearchData($(element)); // needs to run before initial table ajax
			settings = this.getSettingsWithDefaults(element, settings, isAjax);
			this.dt = $(element).DataTable(settings);
			this.$wrapper = $(this.element).closest('.dataTables_wrapper');
			if (triggerLoading)
				this.toggleLoadingIndicator(true);
			this.addAdditionalFilters();
			this.applyDtEvents();
		} else {
			this.dt = $(element).DataTable();
			this.$wrapper = $(this.element).closest('.dataTables_wrapper');
		}
	}

	public static initializeGlobalSettings() {
		if (this.isGlobalInitialized) return;

		$.fn.dataTable.ext.search.push(
			function (settings: any, data: any, dataIndex: number) {
				var $table = $(settings.nTable);
				if (!$table.is('[enable-filter-deleted]')) return true;

				var rowData: any = $table.DataTable().row(dataIndex).data();

				return $table.data('showDeleted') || !rowData.deleted;
			}
		);

		this.isGlobalInitialized = true;
	}

	public dispose(removeTable: boolean): void {
		this.dt.destroy(removeTable);
		this.drilldownToggledEventHandler = <any>undefined;
		this.preSelectEventHandler = <any>undefined;
		this.initCompleteEventHandler = <any>undefined;
		this.refreshedEventHandler = <any>undefined;
		this.searchEventHandler = <any>undefined;
		this.element = <any>undefined;
		this.dt = <any>undefined;
		this.$wrapper = <any>undefined;
		this.isInitialised = false;
	}

	public onDelete(handler: (event: any) => boolean) {
		this.actionEventHandler.add("delete", handler);
	}

	public onDeleted(handler: (event: any) => boolean) {
		this.actionEventHandler.add("deleted", handler);
	}

	public onShowDeletedClicked(handler: (isChecked: boolean) => boolean) {
		this.actionEventHandler.add("showDeletedClicked", handler);
	}

	protected onAdvancedSearch() {
		// override
	}

	private addAdditionalFilters() {
		this.addShowDeletedFilter();
		this.addDateRangeFilter();
		this.addRadioButtonFilter();
	}

	private addShowDeletedFilter() {
		if (!$(this.element).is('[enable-filter-deleted]')) return;

		var inputId = `${this.element.id}_showDeleted`;
		var $label = $(`<label for="${inputId}" class="m-l-10">Show Deleted</label>`);
		var $input = $(`<input id="${inputId}" class="m-l-10 showDeleted" type="checkbox" />`);

		$label.append($input);

		this.$wrapper.find('.additionalFilters').append($label);
		var filterElementId: string = $(this.element).data('custom-filter-for');
		if (filterElementId) {
			var $filterElement = $('#' + filterElementId);
			if ($filterElement.length) {
				this.$wrapper.find('.additionalFilters').append($filterElement.children());
				$filterElement.remove();
			}
			else {
				Logger.warning("No custom filter element found for custom-filter-for", filterElementId);
			}
		}
	}

	private addRadioButtonFilter() {
		if (!$(this.element).is('[enable-radio-search]')) return;

		var primaryValue = this.element.getAttribute("radio-primary");
		var secondaryValue = this.element.getAttribute("radio-secondary")

		var primaryInputId = `${this.element.id}_` + primaryValue;
		var $primaryLabel = $(`<label for="${primaryInputId}" class="m-l-10">` + primaryValue + `</label>`);
		var $primaryInput = $(`<input id="${primaryInputId}" class="m-l-10 radioOptions" type="radio" name="radioType" checked="checked" value="` + primaryValue + `"/>`);

		$primaryLabel.append($primaryInput);

		this.$wrapper.find('.additionalFilters').append($primaryLabel);

		var secondaryInputId = `${this.element.id}_` + secondaryValue;
		var $secondaryLabel = $(`<label for="${secondaryInputId}" class="m-l-10">` + secondaryValue + `</label>`);
		var $secondaryInput = $(`<input id="${secondaryInputId}" class="m-l-10 radioOptions" type="radio" name="radioType"  value="` + secondaryValue + `"/>`);

		$secondaryLabel.append($secondaryInput);

		this.$wrapper.find('.additionalFilters').append($secondaryLabel);
	}

	private addDateRangeFilter() {
		var self = this;
		var $table = $(self.element);
		if (!$table.is('[enable-advanced-search-modal]')) return;

		var $modal = self.getAdvancedSearchModal($table);

		var buttonId = `${self.element.id}_advancedSearchBtn`;
		var $btn = $(`<button id="${buttonId}" class="btn btn-info" type="button">Archives</button>`);
		$btn.click(() => $modal.modal('show'));

		Datepicker.defaultDatepicker($('.datepicker', $('form', self.getAdvancedSearchModal($table))));

		$('.save', $modal).off('click').on('click', (e) => {
			e.preventDefault();
			self.setAdvancedSearchData($table);
			self.refresh();
			$modal.modal('hide');
		});

		this.$wrapper.find('.additionalFilters').append($btn);
	}

	private getAdvancedSearchModal($table: JQuery): JQuery {
		return $($table.attr('enable-advanced-search-modal'));
	}

	private setAdvancedSearchData($table: JQuery) {
		var $form = $('form', this.getAdvancedSearchModal($table));

		var dataArray = $form.serializeArray();
		var advancedSearchData: any = {};
		for (var i = 0; i < dataArray.length; i++) {
			advancedSearchData[dataArray[i].name] = dataArray[i].value;
		}
		$table.data('advancedSearch', advancedSearchData);
	}

	static getAdvancedSearchParameters(element: HTMLElement): {} {
		var data = $(element).data('advancedSearch');
		return data;
	}

	private applyDtEvents() {
		var self = this;
		var $table = $(this.element);

		this.dt.on('user-select', (_event, dt, _type, cell, originalEvent: JQueryEventObject) => {
			var $cell = $(cell.node());
			var preSelect: IPreSelectEvent = {
				dataTable: dt,
				cell: $cell,
				row: $cell.closest('tr'),
				originalEvent: originalEvent
			}
			var results = this.preSelectEventHandler.run(preSelect);
			if (results.some((value) => { return value === false }))
				return false;
			return true;
		});

		this.addInitCompleteEvent(() => {
			self.isInitialised = true;
			return {};
		})

		$table.on('search.dt', function () {
			self.searchEventHandler.run({});
		});

		$table.on('preXhr.dt', (e, settings, data) => {
			this.toggleLoadingIndicator(true);
		});

		$table.on('xhr.dt', (e, settings, data) => {
			this.toggleLoadingIndicator(false);
		});

		$table.on('click', 'a[data-ajax-method="Post"]', function (e) {
			e.preventDefault();
			var url = $(this).attr('href');
			if (!url)
				return;

			AjaxRequest.postRequestPromise<IAjaxReponseData>(url)
				.then((data: IAjaxReponseData) => {
					if (data.message)
						Dialog.success(data.message);
				})
				.catch((err: IAjaxError) => {
					if (!err.isHandled)
						Dialog.failed(err.message);
				});
		});

		$('input[type="checkbox"].showDeleted', this.$wrapper).click(function () {
			$(self.element).data('showDeleted', ($(this).prop('checked')));
			self.actionEventHandler.run('showDeletedClicked', $(this).prop('checked'));
			self.dt.draw();
		});

		this.$wrapper.on('click', '.btn-refresh', () => {
			this.refresh();
		});

		//-- tooltips ---------------------------------------------------------------------

		$table.tooltip({
			selector: '[data-toggle="tooltip"]',
			placement: 'bottom',
			template: `<div class="tooltip dt-tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>`,
			container: 'body',
			boundary: this.element
		});

		$('body').on('mouseenter', '.dt-tooltip,[data-toggle="tooltip"]', e => {
			$table.data('tooltip-hover', true);
		});

		var hideTooltips = (e: any) => {
			$('.dt-tooltip').each(function () {
				var tooltipId = $(<any>this).attr('id');
				var $element = $(`[aria-describedby="${tooltipId}"]`);
				if ($element.length && $element.get(0) !== e.target) {
					$element.tooltip('hide');
				}
			});
			$table.data('tooltip-hover', false);
		}
		$table.on('mouseenter', 'td', hideTooltips);
		$table.on('mouseout', hideTooltips);
		$('.dataTables_scrollBody', this.$wrapper).on('scroll', hideTooltips);

		$table.on('hide.bs.tooltip', e => {
			return !$table.data('tooltip-hover');
		});

		$table.on('show.bs.tooltip', e => {
			hideTooltips(e);
			$table.data('tooltip-hover', true);
		});

		//-- --------------------------------------------------------------------------------

		$table.on('click', '.delete', function () {
			var $action = $(this);
			self.confirmDelete($table, self, $action);
		});
		

		// ---------------------------------------------------------------

		FormUtilities.noAutoFill($('.dataTables_filter input[type="search"]', this.$wrapper));
	}

	//
	public confirmDelete($table: JQuery<HTMLElement>, tableHelper: DataTableHelper, $action: JQuery<HTMLElement>, additonalParams?: KeyedCollection<string>) {
		var ajaxUrlElementId: string = $table.data('delete-ajaxurl-for');
		if (ajaxUrlElementId) {
			var $ajaxUrl = $('#' + ajaxUrlElementId);
			if ($ajaxUrl.length) {
				var ajaxUrl = new AjaxUrl($ajaxUrl);
				var ok = tableHelper.actionEventHandler.run('delete', $action).every(r => r);
				if (!ok) return;
				
				var undelete = $action.data('undelete');
				var entityName = $action.data('entity-name');
				var confirmTitle = $action.data('confirm-title');
				var confirmMessage = $action.data('confirm-message');
				var data = $action.data();

				// Allows additional data to be passed through and posted to the action
				if (additonalParams != undefined && additonalParams != null) {
					additonalParams.keys().forEach(k => {
						data[k] = additonalParams.get(k);
					})
				}

				var url = ajaxUrl.url();
				// Ids can be one or more selected rows when in multiselect mode, or the id attribute of the current action
				var ids = this.getSelectedIds();
				var actionId = $action.data('id').toString();
				if (ids.indexOf(actionId) < 0) {
					ids.push(actionId);
				}
				var isMultipleDelete = ids.length > 1;
				data['ids'] = ids;
				if (!isMultipleDelete) {
					url += "/" + actionId;
				}

				var deleteAction = () => AjaxRequest.sendWithReauthCheck({
					url: url,
					method: 'POST',
					data: data
				}, ajaxUrl.$element)
					.then(() => {
						tableHelper.refresh();
						tableHelper.actionEventHandler.run('deleted', null);
					})
					.catch((err: IAjaxError) => {
						if (!err.isHandled) {
							Dialog.failed(err.message, "Delete failed");
							err.isHandled = true;
						}
						throw err;
					});

				var actionTargetText = isMultipleDelete ? `${this.getSelectedIds().length} items` : `"${entityName}"`;

				var action = undelete ? "Undelete" : "Delete";
				var actionDesc = undelete
					? `"${entityName}" will no longer be marked as deleted.`
					: `${actionTargetText} will be deleted.`;

				DogfishUtilities.ajaxConfirm({
					ajaxUrl: ajaxUrl,
					ajaxRequest: deleteAction,
					dialogSettings: {
						title: confirmTitle || `Confirm ${action}`,
						message: confirmMessage || actionDesc,
						confirmText: "Confirm"
					}
				});
			} else {
				Logger.warning("No ajax-url element found for delete-ajaxurl-for", ajaxUrlElementId);
			}
		}
	}

	public getSelectedIds() {
		var ids = new Array<string>();
		var selectedRowsData = this.getSelectedRowsData();
		for (var i = 0; i < selectedRowsData.length; i++) {
			ids.push(selectedRowsData[i].action.display[0].id);
		}
		return ids;
	}

	private toggleLoadingIndicator(toggle: boolean): void {
		this.$wrapper.toggleClass('processing', toggle);
	}

	private getSettingsWithDefaults(tableElement: HTMLElement, settings: DataTables.Settings | undefined, isAjax: boolean): DataTables.Settings | undefined {
		if (!settings)
			return undefined;

		var defaults = this.getDefaultSettings(tableElement, isAjax);
		var result = $.extend({}, defaults, settings);

		var resultAjax: any = result.ajax;
		var defaultsAjax: any = defaults.ajax;
		if (defaults.ajax) {
			resultAjax.dataSrc = resultAjax.dataSrc || defaultsAjax.dataSrc;
			resultAjax.error = resultAjax.error || defaultsAjax.error;
		}

		return result;
	}

	private getDefaultSettings(tableElement: HTMLElement, isAjax: boolean): DataTables.Settings {
		var helper = this;
		var settings = <DataTables.Settings>{
			buttons: {
				buttons: DataTableHelper.defaultExportButtons(),
				dom: {
					container: {
						className: "dt-buttons text-right"
					},
					button: {
						className: "btn"
					}
				}
			},
			initComplete: function () {
				helper.initCompleteEventHandler.run({});
			},
			processing: true,
			language: {
				loadingRecords: `<div class="loadingIndicator"></div>`,
				processing: `<div class="loadingIndicator"></div>`,
				emptyTable: "No data available"
			},
			columnDefs: [
				DataTableHelper.defaultColumnDef()
			],
			createdRow: DataTableHelper.defaultCreatedRow
		};

		var onFail = () => $('.dataTables_empty', helper.$wrapper)
			.text('Unable to load data');

		if (isAjax)
			settings.ajax = {
				url: DataTableHelper.getDataUrl(tableElement),
				method: 'POST',
				data: function (d: any) {
					return $.extend({}, d, DataTableHelper.getAdvancedSearchParameters(tableElement));
				},
				dataSrc: (response: IAjaxResponse) => {
					if (response.success) {
						helper.$wrapper.removeClass('failed');
						return response.data;
					}

					Logger.error(`Datatable ajax unsuccessful with message: ${response.message}`);
					helper.$wrapper.addClass('failed');
					setTimeout(onFail, 0);
					return [];
				},
				error: (jqXHR, textStatus, errorThrown) => {
					if (textStatus !== "abort") {
						Logger.error("Datatable ajax error", jqXHR, textStatus, errorThrown);
					}

					this.toggleLoadingIndicator(false);
					helper.$wrapper.addClass('failed');
					setTimeout(onFail, 0);
				}
			};
		return settings;
	}

	static defaultCreatedRow(row: any, data: any, index: number) {
		if (data.deleted) {
			$(row).addClass('deleted');
		}
	}

	static defaultColumnDef(): DataTables.ColumnDefsSettings {
		return {
			targets: '_all',
			render: this.defaultColumnDefRender
		};
	}

	static defaultColumnDefRender(data: any, type: any, row: any): any {
		return defaultColumnDefRender(data, type, row);
	}

	static getActionsHtml(actions: ITableAction[]): string {
		return getActionsHtml(actions);
	}

	static defaultExportButtons(): any {
		return {
			extend: 'collection',
			text: 'Export',
			autoClose: true,
			background: false,
			className: 'btn-info',
			buttons: [
				{ extend: 'excelHtml5', exportOptions: { columns: ':not(' + DataTableHelper.noExportClass + ')' } },
				{ extend: 'csvHtml5', exportOptions: { columns: ':not(' + DataTableHelper.noExportClass + ')' } }
			]
		};
	}

	static defaultRefreshButton: DataTables.ButtonSettings = {
		name: "Refresh",
		text: '<i class="fa fa-refresh" aria-hidden="true"></i>',
		className: "btn-light btn-refresh"
	};

	showLoading(): void {
		this.$wrapper.addClass('processing');
	}
	hideLoading(): void {
		this.$wrapper.removeClass('processing');
	}

	getData(): any {
		return this.dt.rows().data();
	}
	getCurrentRowsData(): any {
		return this.dt.rows({ search: 'applied' }).data();
	}
	getCurrentRows(): any {
		return this.dt.rows({ search: 'applied' });
	}
	getSelectedRowsData(): any {
		return this.dt.rows({ selected: true }).data();
	}
	getSelectedRows(): any {
		return this.dt.rows({ selected: true });
	}
	getUnselectedRowsData(): any {
		return this.dt.rows({ selected: false }).data();
	}
	getUnselectedRows(): any {
		return this.dt.rows({ selected: false });
	}
	getAllRows(): any {
		return this.dt.rows();
	}
	getAllRowsData(): any {
		return this.dt.rows().data;
	}
	getRowByRow(row: JQuery<HTMLElement>): any {
		return this.getRow(row);
	}
	getRowDataByRow(row: JQuery<HTMLElement>): any {
		return this.getRow(row).data();
	}

	resize(): void {
		if (!$(this.element).is(':visible'))
			return;

		this.dt.columns.adjust().draw();
		$('[data-toggle="tooltip"]', this.$wrapper).tooltip('update');
	}

	refresh(url?: string): Promise<any> {
		var dfd = $.Deferred();
		var promise = dfd.promise();

		var $scrollingContainer = $(this.element).parent('div.dataTables_scrollBody');
		var scrollTop: number = <number>$scrollingContainer.scrollTop();

		if (url !== undefined) {
			this.dt.ajax.url(url);
		}

		this.dt.ajax.reload((json) => {
			dfd.resolve(json);
			$scrollingContainer.scrollTop(scrollTop);
			this.refreshedEventHandler.run({});
		}, false);

		return promise;
	}

	forColumns(selector: string, callback: (dtColumn: DataTables.ColumnMethods, dtApi: DataTables.Api, dtColumnIndex: number) => any): void {
		var dtApi = this.dt;
		this.dt.columns(selector).every(function (colIdx) {
			callback(this, dtApi, colIdx);
		});
	}
	eachColumn(callback: (dtColumn: DataTables.ColumnMethods, dtApi: DataTables.Api, dtColumnIndex: number) => any): void {
		var dtApi = this.dt;
		this.dt.columns().every(function (colIdx) {
			callback(this, dtApi, colIdx);
		});
	}
	/**
	* Allows you to toggle a rows drilldown
	* @param data 
	* @returns {boolean} whether its showing the drilldown or not
	*/
	toggleDrilldown(data: IDataTableDrilldown) {
		var dtRow = this.getRow(data.tableRow);
		var wasShowing: any = dtRow.child.isShown();
		if (wasShowing) {
			dtRow.child.hide();
			data.tableRow.removeClass(data.className);
		} else {
			dtRow.child(data.data, data.childClassName).show();
			data.tableRow.addClass(data.className);
		}
		var eventInfo: IDrilldownToggledEvent = { isShowing: !wasShowing, row: data.tableRow };
		this.drilldownToggledEventHandler.run(eventInfo);
	}
	collapseDrilldowns(except?: JQuery<HTMLElement>): void {
		var rowToIgnore = except !== undefined ? this.getRow(except) : undefined;
		this.dt.rows().eq(0).each((value, index, dt) => {
			if (rowToIgnore !== undefined && index === rowToIgnore.eq(0)[0])
				return;

			var row = dt.row(index);
			if (row.child.isShown()) {
				row.child.hide();
			}
			var eventInfo: IDrilldownToggledEvent = { isShowing: false, row: $(row.node() as any) };
			this.drilldownToggledEventHandler.run(eventInfo);
		});
	}

	toggleMultiSelect(isMultiSelect: boolean) {
		if (isMultiSelect) {
			this.dt.select.style("multi");
		} else {
			this.deselectRows();
			this.dt.select.style('api');
		}
	}

	selectRow(selector: string): void {
		this.getRowBySelector(selector).select();
	}
	selectRows(): void {
		this.dt.rows().select();
	}
	deselectRows(): void {
		this.dt.rows().deselect();
	}
	deselectSelectedRows(): void {
		this.dt.rows('.selected').deselect();
	}
	addSelectListener(callback: (dtApi: DataTables.Api, selectType: string, indexes: number[]) => any): void {
		var dataTable = this.dt;
		dataTable.on('select', (_event: any, dt: any, type: string, indexes: number[]) => {
			callback(dt, type, indexes);
		});
	}
	addDeselectListener(callback: (dtApi: DataTables.Api, selectType: string, indexes: number[]) => any): void {
		var dataTable = this.dt;
		dataTable.on('deselect', (_event: any, dt: any, type: string, indexes: number[]) => {
			callback(dt, type, indexes);
		});
	}
	addInitCompleteEvent(handler: { (): {} }): void {
		this.initCompleteEventHandler.add(handler);
	}
	addRefreshedEvent(handler: { (): {} }): void {
		this.refreshedEventHandler.add(handler);
	}
	addSearchEvent(handler: { (): {} }): void {
		this.searchEventHandler.add(handler);
	}
	addPreSelectEvent(handler: (data?: IPreSelectEvent) => boolean) {
		this.preSelectEventHandler.add(handler);
	}
	addDrilldownToggledEvent(handler: (data?: IDrilldownToggledEvent) => void) {
		this.drilldownToggledEventHandler.add(handler);
	}

	static getDataUrl(element: HTMLElement): string {
		var url = element.dataset["geturl"];
		return url !== undefined ? url : "";
	}

	static getDomStructure(dom: string): string {
		var result = "";
		var hasFiltering = dom.includes('f');
		var hasLength = dom.includes('l');
		var hasButtons = dom.includes('B');
		var hasTable = dom.includes('t');
		var hasInfo = dom.includes('i');
		var hasPaging = dom.includes('p');
		var hasProcessing = dom.includes('r');

		if (hasFiltering && hasLength && hasButtons)
			result = result + "<'row'B><'row'<'col'l><'col'f>>";
		else if (hasFiltering && hasLength && !hasButtons)
			result = result + "<'row'<'col'l><'col'f>>";
		else if (!hasFiltering && hasLength && hasButtons)
			result = result + "<'row'<'col'l><'col'B>>";
		else if (hasFiltering && !hasLength && hasButtons)
			result = result + "<'row'<'col noTextRight'f<'additionalFilters'>><'col'B>>";

		if (hasTable)
			result = result + "<'row'<'col't" + (hasProcessing ? 'r' : '') + ">>";

		if (hasInfo || hasPaging)
			result = result + "<'row'" + (hasInfo ? "<'col'i>" : '') + (hasPaging ? "<'col'p>" : '') + ">";
		return result;
	}

	private getRowBySelector(selector: string): DataTables.RowMethods {
		return this.dt.row(selector);
	}
	private getRow(tr: JQuery<HTMLElement>): DataTables.RowMethods {
		return this.dt.row(tr);
	}

	static limitSearchExportIds(tableHelper: DataTableHelper) {
		var tableIds = new Array<number>();
		var $table = $(tableHelper.element);
		tableHelper.getCurrentRowsData().each((item: any) => tableIds.push(item.id));
		var searchButton = $table.DataTable().button('exportSearch:name');
		if (tableIds.length > Constants.maxSearchExportIds) {
			searchButton.disable();
			searchButton.nodes().attr('data-original-title', 'Too many results in the table, please use the Advanced export');
		}
		else {
			searchButton.enable();
			searchButton.nodes().attr('data-original-title', '');
		}
	};

	static addSearchExportTooltip(tableHelper: DataTableHelper) {
		var $table = $(tableHelper.element);
		var searchButton = $table.DataTable().button('exportSearch:name').nodes();
		searchButton.attr('data-toggle', 'tooltip');
		searchButton.attr('data-placement', 'left');
		searchButton.tooltip();
	};

	static addSelectFilter(dataTable: HTMLElement): void {
		var dtTable = new DataTableHelper(dataTable);
		dtTable.forColumns('.addSelectFilter', (dtColumn) => {
			var select = $('<select class="form-control"><option value="">All</option></select>')
				.appendTo($(dtColumn.footer()).empty())
				.on('change', function () {
					var selectedVal: string = $(this).val() as string;
					console.log(selectedVal);
					if (selectedVal === 'null')
						selectedVal = '\s*';
					else
						selectedVal = $.fn.dataTable.util.escapeRegex(selectedVal);

					var searchVal = selectedVal ? '^' + selectedVal + '$' : '';
					dtColumn.search(searchVal, true, false).draw();
				});

			dtColumn.data().unique().sort().each((d: any) => {
				var value = d;
				var display = d;
				select.append('<option value="' + value + '">' + display + '</option>');
			});
		});
	}
}

export interface IPreSelectEvent {
	dataTable: DataTables.Api;
	row: JQuery<HTMLElement>;
	cell: JQuery<HTMLElement>;
	originalEvent: JQueryEventObject;
}
export interface IDrilldownToggledEvent {
	isShowing: boolean;
	row: JQuery<HTMLElement>;
}
export interface IDataTableDrilldown {
	tableRow: JQuery<HTMLElement>;
	className: string;
	data: string;
	childClassName?: string;
}